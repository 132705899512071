import { Container, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { FC } from 'react';
import ZHeading from '../components/ZHeading';
import ZSEO from '../components/ZSeo';
import ZFooter from '../components/ZFooter';

const Page: FC = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid(maxWidth: 1600) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const stanna = data.allImageSharp.nodes.find((n) => n.fluid.originalName === 'shannakayFindlater.jpeg').fluid;
  const britney = data.allImageSharp.nodes.find((n) => n.fluid.originalName === 'britneyClarke.jpeg').fluid;
  const ikem = data.allImageSharp.nodes.find((n) => n.fluid.originalName === 'ikemSmith.jpeg').fluid;

  return (
    <>
      <ZSEO title='Meet The Team' />
      <ZHeading firstText={'OUR'} secondText={'Team'} />

      <Container className={classes.container} maxWidth='lg'>
        <Grid container spacing={6}>
          <Grid item md={4} xs={12}>
            <Img className={classes.authorImage} fluid={stanna} />
          </Grid>
          <Grid item md={8} xs={12}>
            <Grid container justify='flex-start'>
              <Typography className={classes.writerName}>Shanna-Kay Findlater</Typography>
              <Typography className={classes.writerHeading}>Chief Sentence Officer</Typography>
            </Grid>

            <Grid className={classes.writerCardPaper}>
              <Typography className={classes.writerBody}>
                I’m Shanna-Kay Findlater, Founder and Head of Copy at the Write Step JM. My passion lies with writing, and my mission is to use words to connect worlds -
                businesses, but especially individuals.
              </Typography>
              <Typography className={classes.writerBody}>
                Starting The Write Step JM. was a response to seeing a need for Copywriting and Content Marketing solutions among individuals and small business owners. With 5+
                years of experience in Communications, and an academic background in Integrated Marketing Communications, responding to this need felt as natural as breathing. In
                August 2018, what was merely a vision became a reality. Now, the Write Step continues to grow from strength to strength.
              </Typography>
              <Typography className={classes.writerBody}>
                When I am not writing to turn a profit, I usually indulge in creating prose and poetry focused on Psychology, Philosophy, and Human Connection shared at
                <span className={classes.writerBodyLink}> shannakayfindlater.medium.com</span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.authorSpacer} />
        <Grid container spacing={6}>
          <Grid item md={4} xs={12}>
            <Img className={classes.authorImage} fluid={britney} />
          </Grid>
          <Grid item md={8}>
            <Grid container justify='flex-start'>
              <Typography className={classes.writerName}>Brithney Clarke</Typography>
              <Typography className={classes.writerHeading}>Contributing Keyboard Finger Dancer</Typography>
            </Grid>

            <Grid className={classes.writerCardPaper}>
              <Typography className={classes.writerBody}>
                Hello, I’m Brithney Clarke, A book lover, avid reader and 2020 recipient of the Prime Minister's Youth Awards for Excellence in Journalism. My passions include
                outreach, environmental preservation, personal development and mental health.
              </Typography>
              <Typography className={classes.writerBody}>
                I am a media professional, employed as the Online Reporter/Producer at Nationwide News Network. I’m also an entrepreneur for the company ‘B’Mindful Candles & More’
                and the Bookmark’d Book Club. These initiatives support sparking awareness on Mental Health– “One flame at a time; one book at a time”
              </Typography>
              <Typography className={classes.writerBody}>I hold a Bachelor of Arts in Integrated Marketing Communications from CARIMAC on the UWI, Mona.</Typography>
            </Grid>
          </Grid>
        </Grid>

        <div className={classes.authorSpacer} />
        <Grid container spacing={6}>
          <Grid item md={4} xs={12}>
            <Img className={classes.authorImage} fluid={ikem} />
          </Grid>
          <Grid item md={8}>
            <Grid container justify='flex-start'>
              <Typography className={classes.writerName}>Ikem Smith</Typography>
              <Typography className={classes.writerHeading}>Contributing Word Wizard</Typography>
            </Grid>
            <Grid className={classes.writerCardPaper}>
              <Typography className={classes.writerBody}>Hey, I’m Ikem Smith. I have ideas and tell stories.</Typography>
              <Typography className={classes.writerBody}>
                My mission is to create entertaining and informative content, using as much humour and emotional honesty as I possibly can. I have 8+ years of experience in pre and
                post-production for video, crafting attention-grabbing visuals for musicians and businesses. I'm able to draw from multiple sources of information and deploy
                lateral thinking to come up with creative solutions.
              </Typography>
              <Typography className={classes.writerBody}>I currently live and work in Kingston, Jamaica.</Typography>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.authorSpacer} />
      </Container>

      <ZFooter />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: 20
  },
  cover: {
    color: '#000',
    height: 160,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    marginBottom: 40,
    borderBottom: `1px solid #eee`
  },

  headingText: {
    // fontSize: 22,
    fontWeight: 'bold'
  },
  writerCardPaper: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  writerName: {
    textAlign: 'left',
    fontSize: 20,
    color: '#008C9E',
    borderRadius: 0,
    fontWeight: 700,
    marginBottom: 20,
    marginRight: 10
  },
  writerHeading: {
    textAlign: 'left',
    fontStyle: 'italic',
    fontSize: 20,
    color: 'black',
    borderRadius: 0,
    fontWeight: 700,
    marginBottom: 20
  },
  writerBody: {
    textAlign: 'left',
    paddingBottom: 30
  },
  writerBodyLink: {
    color: '#CC29CD',
    fontWeight: 'bold'
  },
  autherImageTitleWrapper: {
    backgroundColor: '#008C9E',
    height: 65
  },
  autherImageTitle: {
    color: 'white',
    fontWeight: 700
  },
  authorImage: {
    height: 500,
    borderRadius: 5
  },
  authorSpacer: {
    marginBottom: 70
  }
}));

export default Page;
